import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  CustomProcessEntities,
  CustomProcessData,
  ResponseUpdate,
  CustomProcessSttData,
  DetailCustomProcess,
  CustomProcessReasonList,
  SttFailedDetail,
  RequestPatchReverseDestination,
  STTDetail
} from "@/domain/entities/CustomProcess";
import { container } from "tsyringe";
import { CustomProcessPresenter } from "../presenters/CustomProcessPresenter";
import {
  PatchReverseDestinationApiRequest,
  RequestListCustomProcess,
  UpdateStatusApiRequest
} from "@/data/payload/api/CustomProcessApiRequest";
import { MainAppController } from "./MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import router from "../router";
import { AccountController } from "./AccountController";
import { RequestEstimationPrice } from "@/domain/entities/ShipmentBooking";
import { BookingController } from "./BookingController";
import { ShipmentBookingController } from "./ShipmentBookingController";
import { GetEstimationPrice } from "@/data/payload/api/BookingRequest";
import { ErrorMessageEntities, ModalMessageEntities } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CLIENT_ERROR } from "@/app/infrastructures/misc/Constants";
/* eslint-disable @typescript-eslint/camelcase */

export interface CustomProcessState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "custom-process"
})
class CustomProcessStore extends VuexModule implements CustomProcessState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isError = false;
  public errorCause = "";
  public isErrorDetail = false;
  public isFailed = false;
  public isOpenSuccess = false;
  public filter = {
    startDate: "",
    endDate: "",
    status: ""
  };
  public pagination = {
    page: 1,
    limit: 10
  };
  public customProcessList = new CustomProcessEntities(
    new Pagination(1, 10),
    []
  );
  public customProcessDetailData = new CustomProcessData();
  public isConfirm = false;
  public isFirstRequest = true;

  public customProcessSttDetail = new CustomProcessSttData();
  public detailCustomProcess = new DetailCustomProcess();
  public customProcessReasonList = new CustomProcessReasonList();
  public remarksMisbooking: any = "";

  @Action
  getCustomProcessList(params: RequestListCustomProcess) {
    this.setLoading(true);
    const presenter = container.resolve(CustomProcessPresenter);
    return presenter
      .getCustomProcessList(params)
      .then((res: CustomProcessEntities) => {
        this.setCustomProcess(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setCustomProcess(
          new CustomProcessEntities(new Pagination(0, 0), [])
        );
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public getDetailCustomProcess(id: number) {
    this.setIsLoadingDetail(true);
    const presenter = container.resolve(CustomProcessPresenter);
    return presenter
      .getDetailCustomProcess(id)
      .then((res: DetailCustomProcess) => {
        this.setDetailCustomProcess(res);
        return true;
      })
      .catch((error: any) => {
        this.setErrorDetail(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setIsLoadingDetail(false);
      });
  }

  @Action
  public onUpdate(params: UpdateStatusApiRequest) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CustomProcessPresenter);
    return presenter
      .updateStatus(params)
      .then((res: ResponseUpdate) => res)
      .catch((err: any) => {
        if (params.payload.customProcessStatus.match(/RTS|^REROUTE/gi)) {
          return new ResponseUpdate({
            totalSttFailed: 1,
            sttFailed: [
              new SttFailedDetail({
                error: err.response.data.message.id
              })
            ]
          });
        }

        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Status Gagal!", () =>
            this.onUpdate(params)
          )
        );
        return new ResponseUpdate();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public onPatchReverseDestination(params: {id: any, payload: PatchReverseDestinationApiRequest}) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CustomProcessPresenter);
    return presenter
      .patchReverseDestination(params.id, params.payload)
      .then((res: ResponsePayloadV2) => {
        return {success: true, response: res}
      })
      .catch((err: any) => {
        return {success: false, response: err};
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public initCustomProcessList() {
    this.setFirstRequest(true);
  }

  @Action
  public fetchCustomProcessList() {
    this.setFirstRequest(false);
    this.getCustomProcessList(
      new RequestListCustomProcess({
        page: this.customProcessList.pagination.page,
        limit: this.customProcessList.pagination.limit,
        startDate: new Date(this.filter.startDate).toLocaleDateString("fr-CA"),
        endDate: new Date(this.filter.endDate).toLocaleDateString("fr-CA"),
        status: this.filter.status
      })
    );
  }

  @Action
  public async getCustomProcessSttDetail(params: {
    sttDetail: STTDetail;
    callback: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CustomProcessPresenter);
    return presenter
      .getCustomProcessSttDetail(params.sttDetail)
      .then(res => {
        params.callback(res);
        return res;
      })
      .catch(err => {
        playNotification("error");
        const errorMessage = err.response.data.message.id;
        const isJumbopackH2H = errorMessage === "STT gagal discan karena paket akan diberi status POD setelah diambil oleh penerima.";
        if (isJumbopackH2H) {
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: CLIENT_ERROR,
              title: "Paket akan diambil penerima",
              message: errorMessage,
              onClose: () => MainAppController.closeErrorMessage(),
              buttonSuccessText: "Oke, mengerti"
            })
          );
          return
        }
        if (params.sttDetail.customProcessStatus.includes("RTS") && errorMessage === 'STT tidak bisa di update karena harus menunggu konfirmasi customer/klien.') {
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: CLIENT_ERROR,
              title: "Gagal scan paket",
              message: errorMessage,
              onClose: () => MainAppController.closeErrorMessage(),
              buttonSuccessText: "Oke, mengerti"
            })
          );
          return;
        }
        if (err.response && params.sttDetail.customProcessStatus.includes("OCC")) {
          this.mappingErrorResponseOCC({ err, retry: params });
          return;
        }
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Tambah No. STT Gagal!", () =>
            this.getCustomProcessSttDetail(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchCustomProcessReasonList() {
    const presenter = container.resolve(CustomProcessPresenter);
    return presenter
      .getCustomProcessReasonList()
      .then((res: CustomProcessReasonList) => {
        this.setCustomProcessReasonList(res);
      })
      .catch((err: any) => {
        this.setCustomProcessReasonList([]);
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Gagal Mendapatkan List Reason Custom Process!"
          )
        );
      });
  }

  @Action
  public async getEstimationPrice(params: RequestEstimationPrice) {
    const piecesDelivery: any = [];
    params.pieces.map((item: any) => {
      piecesDelivery.push({
        piece_length: item.dimension.length,
        piece_width: item.dimension.width,
        piece_height: item.dimension.height,
        piece_gross_weight: item.grossWeight
      });
    });
    let resp = await BookingController.getEstimationPrice(
      new GetEstimationPrice({
        originId: params.originId,
        destinationId: params.destinationId,
        commodityId: params.commodityId,
        productType: params.productType,
        accountType: params.accountType,
        accountRefId: params.accountRefId,
        goodsPrice: params.goodsPrice,
        insuranceType: params.insuranceType.split(" ")[1].toLowerCase(),
        isWoodpacking: params.isWoodpacking,
        isHaveTaxId: params.isHaveTaxId,
        pieces: piecesDelivery,
        isCod: false,
        isDfod: false,
        codHandling: "",
        codAmount: 0,
        hideError: params.accountType === "client",
        isDisablePromo: true,
        promoAppliedTo: ""
      })
    );
    if (!resp && params.accountType === "client") {
      resp = await BookingController.getEstimationPrice(
        new GetEstimationPrice({
          originId: params.originId,
          destinationId: params.destinationId,
          commodityId: params.commodityId,
          productType: params.productType,
          accountType: "pos",
          accountRefId: params.accountRefId,
          goodsPrice: params.goodsPrice,
          insuranceType: params.insuranceType.split(" ")[1].toLowerCase(),
          isWoodpacking: params.isWoodpacking,
          isHaveTaxId: params.isHaveTaxId,
          pieces: piecesDelivery,
          isCod: false,
          isDfod: false,
          codHandling: "",
          codAmount: 0,
          isDisablePromo: true,
          promoAppliedTo: ""
        })
      );
    }
    return resp;
  }

  get customProcessReasonListStatus() {
    const find = this.customProcessReasonList.data.filter((e
    ) => e.value === "OCC-HAL" || e.value === "REJECTED");
    const data = find ?? [];

    return this.customProcessReasonList.data
      ? [
        {
          name: router.options?.history.location.includes("update")
            ? "Pilih Status"
            : "Semua Status",
          value: ""
        },
        ...(AccountController.accountData.countryCode === "my"
          ? data
          : this.customProcessReasonList.data)
      ]
      :
      [];
  }

  get shipmentDetail() {
    return ShipmentBookingController.shipmentBookingDetail;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get isInternal() {
    return this.dataProfile.account_type === "internal";
  }
  get isCustomerService() {
    return this.dataProfile.account_type === "customer-service";
  }
  get isConsole() {
    return this.dataProfile.isConsoleAccount
  }

  get isSubConsole() {
    return this.dataProfile.isSubConsoleAccount;
  }

  get typeUser() {
    const type =
      this.isInternal || this.isCustomerService
        ? this.dataProfile.account_type
        : this.dataProfile.account_type_detail.type === "console"
          ? "consolidator"
          : "sub consolidator";
    return type.replace("-", " ");
  }

  @Action
  mappingErrorResponseOCC(param: { err: any; retry: any }) {
    if (
      param.err.response.data?.message?.id.toLowerCase().includes("interpack")
    ) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Tambah No. STT Gagal",
          message:
            "Status terbaru yang Anda pilih hanya untuk No.STT INTERPACK. Cek & atur ulang",
          textSuccess: "OK",
          image: "circle-error",
          onSubmit: () => {
            MainAppController.closeMessageModal();
          }
        })
      );
      return;
    } else if (
      param.err.response.data?.message?.id
        .toLowerCase()
        .includes("status tidak valid")
    ) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Tambah No. STT Gagal",
          message: `Status terbaru yang Anda pilih salah.
            Status harus diperbarui ke <b>“OCC - ${
              param.err.response.data?.message?.id.toLowerCase().includes("imp")
                ? "IMP"
                : "EXP"
            }”</b>. Cek & atur ulang`,
          textSuccess: "OK",
          image: "circle-error",
          onSubmit: () => {
            MainAppController.closeMessageModal();
          }
        })
      );
      return;
    }
    MainAppController.showErrorMessage(
      parsingErrorResponse(param.err, "Tambah No. STT Gagal!", () =>
        this.getCustomProcessSttDetail(param.retry)
      )
    );
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.isFirstRequest = value;
  }

  @Mutation
  public setRemarksMisbooking(value: any) {
    this.remarksMisbooking = value;
  }

  @Mutation
  public setCustomProcessSttDetail(data: CustomProcessSttData) {
    this.customProcessSttDetail = data;
  }

  @Mutation
  public setCustomProcessDetailData(data: CustomProcessData) {
    this.customProcessDetailData = data;
  }

  @Mutation
  public setResetCustomProcessDetailData() {
    this.customProcessDetailData = new CustomProcessData();
  }

  @Mutation
  private setCustomProcess(data: CustomProcessEntities) {
    this.customProcessList = data;
  }

  @Mutation
  private setDetailCustomProcess(data: DetailCustomProcess) {
    this.detailCustomProcess = data;
  }

  @Mutation
  public setIsLoadingDetail(val: boolean) {
    this.isLoadingDetail = val;
  }

  @Mutation
  public setConfirm(value: boolean) {
    this.isConfirm = value;
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }

  @Mutation
  public setStatus(value: any) {
    this.filter.status = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorDetail(value: boolean) {
    this.isErrorDetail = value;
  }

  @Mutation
  public setFailed(value: boolean) {
    this.isFailed = value;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.isOpenSuccess = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setCustomProcessReasonList(value: any) {
    this.customProcessReasonList = value;
  }

  @Mutation
  public setFilter(val: any) {
    this.filter = val;
  }

  isEditAddress = false;
  consigneePhone:any = "";
  unableProccessMisbooking = false;

  @Mutation
  public setIsEditAddress(bool: boolean) {
    this.isEditAddress = bool;
  }

  @Mutation
  public setUnableProccessMisbooking(bool: boolean) {
    this.unableProccessMisbooking = bool;
  }

  @Mutation
  public setConsigneePhone(str: any) {
    this.consigneePhone = str;
  }

  reverseDestination = new RequestPatchReverseDestination({});

  @Mutation 
  public setReverseDestination(payload: RequestPatchReverseDestination) {
    this.reverseDestination = payload;
  }
}

export const CustomProcessController = getModule(CustomProcessStore);
